<template>
  <div class="parent">
    <!-- Video -->
    <div class="div1">
      <div class="logo-container">
        <a @click="addLogo" href="javascript:" data-bs-toggle="modal" data-bs-target="#modal-layout-logo"
          class="logo-box" aria-label="Logo canal">
          <img v-if="contentPreview.logo" :src="contentPreview.logo" class="logo-box-img">
          <p v-else class="mb-0 py-2 px-3"></p>
        </a>
        <AddEditLayoutButton @click="addLogo" href="javascript:" data-bs-toggle="modal"
          data-bs-target="#modal-layout-logo" v-if="contentPreview.logo" edit media="logo" />
        <AddEditLayoutButton @click="addLogo" href="javascript:" data-bs-toggle="modal"
          data-bs-target="#modal-layout-logo" v-else add media="logo" />
      </div>
      <a @click="addVideo" href="javascript:" class="thumbnail-container">
        <template v-if="isYoutubeVideo(contentPreview.videoImg)">
          <img class="image-box" :src="getYoutubeThumbnail(contentPreview.videoImg)"
            alt="contentPreview.nombre_archivo" />
        </template>
        <template v-else-if="isVideo(contentPreview.videoImg)">
          <!-- Renderiza el thumbnail del video -->
          <VueVideoThumbnail class="video-box" :video-src="contentPreview.videoImg" :snapshot-at-duration-percent="1"
            :chunks-quantity="1" snapshotScaleType="contain" :width="618" :height="345"
            :style="{ '--color': contentPreview.color, '--color-hover': `${darkenHexColor(contentPreview.color)}` }" />
        </template>

        <template v-else-if="isImage(contentPreview.videoImg)">
          <!-- Renderiza la imagen si no es un video -->
          <img :src="contentPreview.videoImg" class="image-box" />
        </template>
        <p v-else class="thumbnail-container-text mb-0">
          <font-awesome-icon :icon="['fas', 'play-circle']" size="6x" class="add-media-icon" />
        </p>
        <span class="thumbnail-container-video-btn">
          <AddEditLayoutButton v-if="contentPreview.videoImg" edit media="contenido" />
          <AddEditLayoutButton v-else add media="contenido" />
        </span>
        <span v-if="contentPreview.videoImg" class="thumbnail-container-pagination" @click.stop="() => { }">
          <PaginationLayoutButton :currentPage.sync="contentPreview.activeVideoPage"
            :totalPages="contentPreview.numVideos" @arrowClick="changeDisplayedVideo" />
        </span>
      </a>
    </div>

    <!-- Banners -->
    <div class="div2">
      <a @click="addBanner" href="javascript:" class="thumbnail-container">
        <img v-if="contentPreview.bannerImg" class="banner-box" :src="contentPreview.bannerImg" />
        <p v-else class="thumbnail-container-text mb-0">
          <font-awesome-icon :icon="['fas', 'image']" size="6x" class="add-media-icon" />
        </p>
        <span class="thumbnail-container-banner-btn">
          <AddEditLayoutButton v-if="contentPreview.bannerImg" edit media="banner" />
          <AddEditLayoutButton v-else add media="banner" />
        </span>
        <span v-if="contentPreview.bannerImg" class="thumbnail-container-pagination" @click.stop="() => { }">
          <PaginationLayoutButton :currentPage.sync="contentPreview.activeBannerPage"
            :totalPages="contentPreview.numBanners" @arrowClick="changeDisplayedBanner" />
        </span>
      </a>
    </div>

  </div>
</template>

<script>
import { isImage, isVideo } from "@/utils/formats.js";
import { getYoutubeThumbnail } from "@/utils/youtube";
const VueVideoThumbnail = () => import('vue-video-thumbnail')
const AddEditLayoutButton = () => import("./AddEditLayoutButton.vue")
const PaginationLayoutButton = () => import("./PaginationLayoutButton.vue")
export default {
  components: {
    VueVideoThumbnail,
    AddEditLayoutButton,
    PaginationLayoutButton
  },

  props: {
    contentPreview: Object
  },
  inject: [
    'addLogo',
    'addVideo',
    'addBanner',
    'changeDisplayedVideo',
    'changeDisplayedBanner'
  ],
  methods: {
    isVideo(url) {
      return isVideo(url)
    },
    isImage(url) {
      return isImage(url)
    },
    isYoutubeVideo(url) {
      return url.includes("youtube");
    },
    getYoutubeThumbnail(url) {
      return getYoutubeThumbnail(url, true);
    },
  }

};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/abstracts/_variables.scss";

.image-box {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.add-media-icon {
  color: $gray-2;
}

.logo-container {
  position: absolute;
  display: flex;
  gap: 6px;
  align-items: center;
  top: 20px;
  left: 20px;
  z-index: 1;
}

.logo-box {
  display: flex;
  width: 60px;
  height: 60px;
  border: 1px solid $border-color;
  background-color: lighten(black, 92%);

  &:hover {
    background-color: lighten($blue-color, 45%);
  }
}

.thumbnail-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-decoration: none;
  color: gray;

  &-text {
    text-align: center;
  }

  &-video-btn {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  &-banner-btn {
    position: absolute;
    top: 12px;
  }

  &-pagination {
    position: absolute;
    bottom: 12px;
  }
}


.video-box {
  width: 100%;
  height: 100%;
  align-content: center;
  background-color: var(--color);

  &:hover {
    background-color: var(--color-hover);
  }
}

.banner-box {
  width: 100%;
  height: 100%;
}

$base-unit: 49px; // Base unit remains the same

.parent {
  height: max-content;
  width: max-content;
  display: grid;
  grid-template-columns: 12.6*$base-unit 4*$base-unit;
  grid-template-rows: 9*$base-unit;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  box-sizing: border-box; // Include border in the element's size
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
  position: relative;
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
}


/* Apply common styles to all divs */
.div1,
.div2 {
  width: 100%; // Fill the container
  height: 100%;
  overflow: hidden;
  box-sizing: border-box; // Include border in the div's size
  background-color: $background-color-unset;

  &:hover {
    background-color: lighten($blue-color, 45%);
  }
}

/* BORDERS */
.div1,
.div2 {
  border-right: 1px solid $border-color;
  /* Right border for all */
  border-bottom: 1px solid $border-color;
  /* Bottom border for all */
}

.div1 {
  border-left: 1px solid $border-color;
}

.div1,
.div2 {
  border-top: 1px solid $border-color;
}
</style>